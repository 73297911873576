<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 v-if="!successfullySignedUp" class="app-page-title">{{ $t("sign_up.Create_a_user") }}</h3>
  <div v-if="!successfullySignedUp" class="app-central-container">
    <div class="app-central-card">
      <v-card-text>
        <v-form v-model="inputsValid" @submit.prevent="signUp">
          <v-container>
            <div class="sign-up-inputs">
              <v-row>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('common_user_settings.E_mail')"
                  required
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  hint="At least 8 characters"
                  :label="$t('common_user_settings.Password')"
                  required
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="passwordConfirm"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordConfirmRules(password)"
                  :type="showPassword ? 'text' : 'password'"
                  hint="At least 8 characters"
                  :label="$t('common_user_settings.Confirm_password')"
                  required
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-row>

              <v-row>
                <checkbox-field class="checkbox-field" v-model="canSendMarketingEmails">{{
                  $t("sign_up.Knittable_can_send_me_marketing_emails")
                }}</checkbox-field>
              </v-row>

              <v-row>
                <checkbox-field class="checkbox-field" v-model="acceptTermsAndConditions">
                  <span>{{ $t("sign_up.I_accept_terms_and_conditions") }}</span>
                  <localized-link to="termsandprivacy">
                    <v-icon class="terms-and-conditions-link" icon="mdi-link" />
                  </localized-link>
                </checkbox-field>
              </v-row>
            </div>

            <main-button type="submit" class="submit-btn" color="purple" :disabled="!signUpFormValid">
              {{ $t("sign_up.Submit") }}
            </main-button>
          </v-container>
        </v-form>

        <v-text-field
          v-model="signUpErrorMessage"
          label="Error Message"
          :value="signUpErrorMessage"
          readonly
          prepend-icon="mdi-alert-circle-outline"
          v-if="signUpErrorMessage"
        ></v-text-field>
      </v-card-text>
      <div>
        {{ $t("sign_up.Already_a_registered_user_") }}
        <localized-link to="signin">{{ $t("sign_up.Sign_in_instead_") }}</localized-link>
      </div>
    </div>
  </div>
  <div v-else class="content-container">
    <h6 class="app-page-title">{{ $t("sign_up.Suceess_Your_account_has_been_created") }}</h6>
    <div class="app-page-subtitle">
      {{ $t("sign_up.Your_free_trial_starts_now") }}
    </div>
    <p class="verification-email-note">
      {{ $t("sign_up.We_ve_sent_a_verification_email_") }}
    </p>
    <small
      >{{ $t("sign_up.Need_help_") }}
      <a href="mailto: hello@knittable.com">{{ $t("sign_up.Contact_our_support_team") }}</a>
    </small>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import CheckboxField from "../components/general/CheckboxField.vue";
import { getConsentFromLocalStorage } from "../lib/userTrackingUtils";
import MainButton from "../components/general/MainButton.vue";
import { useAuthStore } from "../stores/auth";
import { useLangStore } from "./../stores/lang";
import LocalizedLink from "../components/LocalizedLink.vue";
import { emailRules, passwordRules, passwordConfirmRules } from "./../lib/formRules.js";

const termsVersion = "1.0.0";

const langStore = useLangStore();
const locale = langStore.getLocale();

const authStore = useAuthStore();

const props = defineProps(["showDialog"]);

const inputsValid = ref(false);
const email = ref("");
const password = ref("");
const passwordConfirm = ref("");
const canSendMarketingEmails = ref(true);
const acceptTermsAndConditions = ref(false);
const showPassword = ref(false);

const signUpErrorMessage = ref("");

const successfullySignedUp = ref(false);
const loading = ref(false);

async function signUp() {
  try {
    signUpErrorMessage.value = "";
    loading.value = true;

    const [canTrack, savedAt] = getConsentFromLocalStorage();
    await authStore.signUp(
      email.value,
      password.value,
      passwordConfirm.value,
      locale.value,
      canSendMarketingEmails.value,
      acceptTermsAndConditions.value,
      termsVersion,
      canTrack
    );

    successfullySignedUp.value = true;
  } catch (error) {
    signUpErrorMessage.value = error.msg;
  } finally {
    loading.value = false;

    // doing this because form is large and can cause
    // scroll down, hiding the success notification
    window.scrollTo(0, 0);
  }
}

const signUpFormValid = computed(() => {
  return inputsValid.value && acceptTermsAndConditions.value;
});
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";
p {
  margin-bottom: 1rem;
}

.content-container {
  margin-bottom: 2rem;
}

.sign-up-inputs {
  padding-bottom: 1rem;
}

.checkbox-field {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.submit-btn {
  margin-top: 1.5rem;
}

.terms-and-conditions-option {
  display: flex;
  width: 100%;
  align-items: center;
}

.terms-and-conditions-link {
  margin-left: 0.25rem;
  color: var(--knittable-lav-darker);
}

.verification-email-note {
  @include paragraphMediumBold;

  color: var(--knittable-begonia);

  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1.5rem;
}
</style>
