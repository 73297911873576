<template>
  <div class="app-pricing-sheet app-front-page-sheet">
    <front-page-section-title
      :title="$t('pricing.Choose_what_fits_you_best')"
      :color-inverted="true"
    ></front-page-section-title>
    <subscription-plans :active="authToken != null"></subscription-plans>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import FrontPageSectionTitle from "./FrontPageSectionTitle.vue";
import SubscriptionPlans from "./SubscriptionPlans.vue";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);
</script>

<style>
.app-pricing-sheet {
  background-color: var(--knittable-isa);
}
</style>
