<template>
  <div class="app-news-letter-sign-up-sheet app-front-page-sheet">
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <h6 class="title">{{ $t("news_letter_sign_up.Join_the_inner_circle") }}</h6>
        <div class="app-news-letter-sign-up-text">
          {{ $t("news_letter_sign_up.Want_to_be_the_first_to_know") }}
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="app-news-letter-sign-up-form-col">
        <div class="app-news-letter-sign-up-form-container">
          <form @submit.prevent="signUpToNewsletter" class="app-news-letter-sign-up-form">
            <input
              type="email"
              v-model="email"
              placeholder="Enter your email"
              required
              class="input-field"
              @input="
                errorOccurred = false;
                success = false;
              "
            />
            <button type="submit" class="sign-up-button">Subscribe</button>
          </form>
          <div v-if="errorOccurred" class="error message">{{ errorMessage }}</div>
          <div v-else-if="success" class="success message">
            {{ $t("news_letter_sign_up.Thank_you_for_subscribing") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { addToMailingList } from "../services/mailingList";
import { useI18n } from "vue-i18n";

const email = ref("");
const success = ref(false);
const errorOccurred = ref(false);
const errorMessage = ref(null);

const { t } = useI18n();

const signUpToNewsletter = async () => {
  try {
    await addToMailingList(email.value, "newsletter");
    success.value = true;
  } catch (error) {
      errorOccurred.value = true;
    if (error.response && error.response.status === 409) {
      errorMessage.value = t(
        "news_letter_sign_up.This_email_is_already_subscribed_to_the_newsletter"
      );
    } else {
      errorMessage.value = t("common.default_error_message");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";
.app-news-letter-sign-up-sheet {
  background-color: var(--knittable-opal-dark);
  margin-bottom: -2.5rem;
  padding-bottom: 7rem;
}

.title {
  margin-top: 4rem;
  margin-left: 7rem;
  margin-right: 7rem;
  text-align: left;
  color: white;
}

.app-sm-and-down .title {
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.app-news-letter-sign-up-text {
  @include paragraphLargeBold;
  margin-top: 1rem;
  margin-left: 7rem;
  margin-right: 7rem;
  color: white;
  text-align: left;
}

.app-sm-and-down .app-news-letter-sign-up-text {
  @include paragraphMediumBold;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.app-news-letter-sign-up-form-container {
  max-width: 700px;
  min-width: 500px;
  display: inline-block;
}

.app-sm-and-down .app-news-letter-sign-up-form-container {
  min-width: 400px;
}

.app-news-letter-sign-up-form {
  display: flex;
  height: 4rem;
  border-radius: 60px;
  max-width: 600px;

  @include paragraphMediumBold;
  .input-field {
    background-color: white;
    width: 60%;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    padding: 0.5rem;
    text-align: center;
  }
  .sign-up-button {
    background-color: var(--knittable-yama-medium);
    width: 40%;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    padding: 0.5rem;
    text-align: center;
    color: white;
    transition: background-color 0.3s ease;
  }
  .sign-up-button:hover {
    background-color: var(--knittable-yama);
  }
}

.app-sm-and-down .app-news-letter-sign-up-form {
  @include paragraphSmallBold;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.message {
  @include paragraphMediumBold;
  margin-top: 1rem;
}

.success.message {
  color: var(--knittable-yama-medium);
}

.error.message {
  color: var(--knittable-begonia);
}

.app-news-letter-sign-up-form-col {
  margin-top: 7rem;
}

.app-sm-and-down .app-news-letter-sign-up-form-col {
  margin-top: 3rem;
}
</style>
