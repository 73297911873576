<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("common.Beanie") }}</h3>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <!-- <p class="app-alert" v-if="form.decreaseType == 'cross' && crossDecreaseCount < 4">
                {{ $t('beanie.There_are_too_few_cast_on_stitches')}}
              </p> -->
        <div class="app-form-description">{{ $t("beanie.pattern_description") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Measurements") }}
              </div>
            </header>
            <persons-select-field
              v-model="formData.chosenPerson"
              @item-selected="(item) => onPersonSelected(formData, item.value)"
            >
            </persons-select-field>
            <select-field
              v-if="formData.chosenPerson != null && formData.chosenPerson.value != 'custom'"
              v-model="formData.chosenSize"
              ref="selectSizesField"
              :label="t('common3.Size_')"
              :placeholder="t('common3.Choose_a_size')"
              :items="sizes"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onSizeSelected(formData, item.value)"
            ></select-field>

            <advanced-settings
              :title="t('common.Advanced_settings')"
              v-if="formData.chosenSize != null"
            >
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.headCircumf"
                  :label="t('beanie.Head_circumference_in_cm_')"
                  :description="t('beanie.Head_circumference_description')"
                  :placeholder="t('beanie.Type_in_head_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.foldLength"
                  :label="t('beanie.Brim_height_in_cm_')"
                  :description="t('beanie.Brim_height_description')"
                  :placeholder="t('beanie.Type_in_brim_height')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div class="app-pattern-form-section" v-if="formData.chosenSize != null">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <ribbing-toggle-field v-model="formData.cuffPatternRepCount"> </ribbing-toggle-field>
            <toggle-field
              :label="t('beanie.Ribbing_coverage')"
              :options="stockinetteTopOptions"
              v-model="formData.stockinetteTop"
            ></toggle-field>
            <toggle-field
              :label="t('beanie.Decrease_type')"
              :options="decreaseTypeOptions"
              v-model="formData.decreaseType"
            ></toggle-field>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="
              formData.cuffPatternRepCount != null &&
              formData.stockinetteTop != null &&
              formData.decreaseType != null
            "
          >
            <knitting-gauge
              v-model:row-gauge="formData.rowGauge"
              v-model:stitch-gauge="formData.stitchGauge"
              v-model:estimate-row-gauge-enabled="formData.estimateRowGaugeEnabled"
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="newVersionAvailable">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showPattern">
        <PatternToolBar
          :title="$t('common.The_Knittable_beanie')"
          :pattern-type="patternType"
          :description="$t('beanie.pattern_description')"
          :stitch-gauge="formData.stitchGauge"
          :row-gauge="formData.rowGauge"
          :properties="[
            { label: $t('common.Size_category'), value: formData.chosenPerson?.key },
            { label: $t('common.Size'), value: formData.chosenSize?.key },
          ]"
          :measurements="[
            { label: $t('beanie.Head_circumference_in_cm_'), value: formData.headCircumf },
            { label: $t('beanie.Brim_height_in_cm_'), value: formData.foldLength },
          ]"
          :design-details="[
            { label: $t('common.Ribbing_type'), value: formData.cuffPatternRepCount?.key },
            { label: $t('beanie.Ribbing_coverage'), value: formData.stockinetteTop?.key },
            { label: $t('beanie.Decrease_type'), value: formData.decreaseType?.key },
          ]"
        >
          <pattern-renderer
            :current-version="currentVersion"
            :versions="versions"
            :disabled="buttonIsEnabled"
            v-model:bookmark="bookmark"
            :data="beanieData"
            @update:bookmark="onBookmarkUpdated"
            :pattern-type="patternType"
          ></pattern-renderer>
        </PatternToolBar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import RibbingToggleField from "./../../components/patterns/RibbingToggleField.vue";
import { calculateBeanie } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import {
  sizesSelectFieldDisabled,
  measurementFieldsDisabled,
  hasEmptyValues,
} from "../../lib/patternFormUtils";
import ToggleField from "../../components/general/ToggleField.vue";
import { loadCurrentProject, saveCurrentProject, saveBookmark } from "../../services/project";
import { useLangStore } from "./../../stores/lang";
import PatternToolBar from "../../components/PatternToolBar.vue";
const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();

const router = useRouter();

const patternType = "beanie";
const bookmark = ref(null);

const latestVersion = "1.0.0";
const currentVersion = ref(latestVersion);
const versions = {
  "1.0.0": "BeaniePattern",
};

const { t } = useI18n();

const formData = ref({
  chosenPerson: null,
  chosenSize: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  headCircumf: null,
  foldLength: null,
  cuffPatternRepCount: null,
  stockinetteTop: null,
  decreaseType: null,
});

const selectSizesField = ref(null);
const sizes = ref(null);
let beanieData = ref(null);

const sizeSelectorDisabled = computed(() => sizesSelectFieldDisabled(formData.value));
const valuesDisabled = computed(() => measurementFieldsDisabled(formData.value));

const formHasChanges = ref(false);
const showPattern = ref(false);

const isSubscribedOrOnTrial = ref(true);

watch(
  () => formData.value.chosenPerson,
  () => {
    if (selectSizesField.value) {
      selectSizesField.value.resetSelection();
    }
    switch (formData.value.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      case "grown_up":
        sizes.value = sizeGrownUp.value;
        break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(formData, newChosenPerson) {
  if (newChosenPerson == "custom") {
    formData.chosenSize = "custom";
  } else {
    formData.chosenSize = null;
  }
  formData.headCircumf = null;
  formData.foldLength = null;
}

function onSizeSelected(formData, newChosenSize) {
  if (newChosenSize != null) {
    formData.headCircumf = newChosenSize.sizeHeadCircumf;
    formData.foldLength = newChosenSize.sizeFoldLength;
  }
}

onMounted(async () => {
  const project = await loadCurrentProject(patternType);
  if (project != null) {
    Object.assign(formData.value, project.settings);
    bookmark.value = project.bookmark;
    currentVersion.value = project.patternVersion;
    calculatePattern(false, false);
  }
});

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const formIsValid = computed(() => {
  return !hasEmptyValues(formData.value);
});

const newVersionAvailable = computed(() => {
  return currentVersion.value != latestVersion;
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (upgrade) {
      currentVersion.value = latestVersion;
    }
    if (formIsValid.value) {
      await getBeanieParameters();
      formHasChanges.value = false;
      if (saveProject) {
        await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getBeanieParameters() {
  const beanieParameters = await calculateBeanie(
    formData.value.stitchGauge / 10,
    formData.value.rowGauge / 10,
    formData.value.headCircumf,
    formData.value.foldLength,
    formData.value.cuffPatternRepCount.value,
    formData.value.stockinetteTop.value,
    formData.value.decreaseType.value
  );

  showPattern.value = true;
  beanieData.value = beanieParameters;
  return beanieParameters;
}

const goToSubscription = async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
  router.push(composeRoute(locale.value, "subscription", "beanie"));
};

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

const onBookmarkUpdated = async (newBookmark) => {
  bookmark.value = newBookmark;
  await saveBookmark(patternType, bookmark.value);
};

const stockinetteTopOptions = computed(() => {
  return [
    { key: t("beanie.Whole_beanie_ribbed_option"), value: false },
    { key: t("beanie.Only_brim_ribbed_option"), value: true },
  ];
});

const decreaseTypeOptions = computed(() => {
  return [
    { key: t("beanie.Even_decrease_option"), value: "even" },
    { key: t("beanie.Cross_decrease_option"), value: "cross" },
  ];
});

const sizeBabyChildYouth = computed(() => {
  return [
    { key: "0-3 " + t("common.months"), value: { sizeFoldLength: 5.5, sizeHeadCircumf: 39.5 } },
    { key: "3-6 " + t("common.months"), value: { sizeFoldLength: 5.5, sizeHeadCircumf: 42.5 } },
    { key: "6-9 " + t("common.months"), value: { sizeFoldLength: 6.0, sizeHeadCircumf: 45.0 } },
    { key: "9-12 " + t("common.months"), value: { sizeFoldLength: 6.0, sizeHeadCircumf: 46.5 } },
    { key: "1-2 " + t("common.years"), value: { sizeFoldLength: 6.5, sizeHeadCircumf: 48.0 } },
    { key: "2-4 " + t("common.years"), value: { sizeFoldLength: 6.5, sizeHeadCircumf: 49.0 } },
    { key: "4-6 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 50.0 } },
    { key: "6-8 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 51.0 } },
    { key: "8-10 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 52.0 } },
    { key: "10-12 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 52.5 } },
  ];
});
const sizeGrownUp = computed(() => {
  return [
    { key: "XS", value: { sizeFoldLength: 7.0, sizeHeadCircumf: 53.0 } },
    { key: "S", value: { sizeFoldLength: 7.5, sizeHeadCircumf: 55.0 } },
    { key: "M", value: { sizeFoldLength: 7.5, sizeHeadCircumf: 56.5 } },
    { key: "L", value: { sizeFoldLength: 8.0, sizeHeadCircumf: 59.0 } },
    { key: "XL", value: { sizeFoldLength: 8.5, sizeHeadCircumf: 61.0 } },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
