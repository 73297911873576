<template>
  <div class="app-get-started-sheet app-front-page-sheet">
    <v-row class="app-get-started-row">
      <v-col cols="12" sm="12" md="7" class="p0 left-col">
        <div class="app-get-started-text-container">
          <div class="app-get-started-super-title">
            {{ $t("get_started.Your_knitting_design_tool") }}
          </div>
          <div class="app-get-started-title">
            <span class="app-get-started-title-1">{{
              $t("get_started.Unleash_your_creativity_1")
            }}</span>
            <span class="app-get-started-title-2">
              {{ $t("get_started.Unleash_your_creativity_2") }}
            </span>
            <span class="app-get-started-title-3">
              {{ $t("get_started.Unleash_your_creativity_3") }}
            </span>
          </div>
          <div v-if="authToken == null" class="app-get-started-button-container">
            <button :to="`/${locale}/signup`" class="app-get-started-button">
              {{ $t("get_started.Try_it_out") }}
            </button>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="5" class="p0">
        <div class="app-image-container">
          <div class="app-front-page-img-box"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useAuthStore } from "../stores/auth";
import { useLangStore } from "./../stores/lang";

const langStore = useLangStore();
const locale = langStore.getLocale();

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";
.app-get-started-sheet {
  padding-top: 8.5rem; // 6.5rem of this is to account for the navbar
  background-color: white;
}

.left-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-get-started-text-container {
  padding-left: 5.5rem;
  margin-right: 10%;
  text-align: left;
}

.app-sm-and-down .app-get-started-text-container {
  padding-left: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 0.5rem;
}

.app-get-started-super-title {
  color: var(--knittable-lav-darker);

  @include subtitleLarge;
  font-style: bold;
  font-weight: 600;
  letter-spacing: 0.04em;

  padding-bottom: 1rem;
}

.app-sm-and-down .app-get-started-super-title {
  font-size: 1.5rem;
}

.app-front-page-img-box {
  background: url("./../assets/knitting_hands.jpg"), lightgray 50% / cover no-repeat;
  background-size: cover;

  border-radius: 70px;
  width: 100%;
  aspect-ratio: 1;

  filter: drop-shadow(25px 25px var(--knittable-yama));
  margin-bottom: 25px; // to account for the shadow
  margin-right: 25px; // to account for the shadow

  background-position: center center;
}

.app-sm-and-down .app-front-page-img-box {
  border-radius: 60px;
  width: 75%;

  filter: drop-shadow(20px 20px var(--knittable-yama));
  margin-bottom: 20px; // to account for the shadow
  margin-right: 20px; // to account for the shadow
}

.app-image-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}

.app-sm-and-down .app-image-container {
  padding-right: 0rem;
}

.app-get-started-title {
  @include heading2;
}

.app-sm-and-down .app-get-started-title {
  font-size: 3rem;
}

.app-get-started-title-1 {
  color: var(--knittable-lav-darker);
}

.app-get-started-title-2 {
  color: var(--knittable-begonia);
}

.app-get-started-title-3 {
  color: var(--knittable-begonia);
}

.app-get-started-button {
  @include buttonText;
  background: var(--knittable-yama);
  color: white;
  border-radius: 5rem;
  padding: 1rem 4rem;
  margin-top: 2rem;
}

.app-sm-and-down .app-get-started-button-container {
  text-align: left;
}
</style>
