<template>
  <wanted-pattern
    :data="props.data"
    :disabled="props.disabled"
    v-model:bookmark="internalBookmark"
  ></wanted-pattern>
  <div class="app-pattern-footer">
    <div class="share">{{ $t("common.Share_your_version", { patternHashtag: hashtag }) }}</div>
    <div class="license">
      {{ $t("common.Licensing_disclaimer", { email: "hello@knittable.com" }) }} 
      © {{ new Date().getFullYear() }} Knittable.
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, watch, computed } from "vue";

const props = defineProps({
  currentVersion: {
    type: String,
  },
  versions: {
    type: Object,
  },
  bookmark: {
    type: String,
  },
  data: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  bookmark: {
    type: String,
  },
  patternType: {
    type: String,
    required: true,
  },
});

const internalBookmark = computed({
  get: () => props.bookmark,
  set: (value) => {
    emit("update:bookmark", value);
  },
});

const emit = defineEmits();

watch(
  () => props.currentVersion,
  () => {
    wantedPattern = defineAsyncComponent(dynamicImport);
  },
  { deep: true }
);

const dynamicImport = async () => {
  return import(`./${props.versions[props.currentVersion]}.vue`);
};

let wantedPattern = defineAsyncComponent(dynamicImport);

const hashtag = computed(() => {
  switch (props.patternType) {
    case 'sweater':
      return '#knittablesweater';
    case 'sock':
      return '#knittablesocks';
    case 'mitten':
      return '#knittablemittens';
    case 'beanie':
      return '#knittablebeanie';
    default:
      return ''; 
  }
});
</script>

<style scoped lang="scss">
@import "../../../scss/styles.scss";

.app-pattern-footer {
  background-color: var(--knittable-opal);
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.75rem;
  text-align: center;
}

.app-pattern-footer .share {
  @include paragraphMediumBold;
  margin-bottom: 0.5rem;
}

.app-pattern-footer .license {
  @include paragraphSmallRegular;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
