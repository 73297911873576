<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("user_profile.User_Profile") }}</h3>
  <v-row class="user-profile-container">
    <v-card-text>
      <v-text-field
        :label="$t('common_user_settings.First_name')"
        v-model="firstName"
        :readonly="!isEditMode"
        :class="getTextFieldClass()"
      ></v-text-field>
      <v-text-field
        :label="$t('common_user_settings.Last_name')"
        v-model="lastName"
        :readonly="!isEditMode"
        :class="getTextFieldClass()"
      ></v-text-field>
      <v-text-field
        :label="$t('common_user_settings.E_mail')"
        v-model="email"
        readonly
        class="app-user-profile-readonly-field"
      ></v-text-field>
    </v-card-text>
  </v-row>
  <v-row class="user-profile-button-container">
    <template v-if="isEditMode">
      <main-button @click="cancelEdit">{{ $t("common5.Cancel") }}</main-button>
      <main-button @click="saveProfile">{{ $t("user_profile.Save") }}</main-button>
    </template>
    <template v-else>
      <main-button @click="toggleEditMode">{{ $t("user_profile.Edit_profile") }}</main-button>
      <v-spacer></v-spacer>
      <main-button @click="showResetPasswordDialog = true">{{
        $t("user_profile.Reset_password")
      }}</main-button>
      <main-button @click="showDeleteConfirmation = true" color="red">{{
        $t("user_profile.Delete_account")
      }}</main-button>
    </template>
  </v-row>

  <!-- Delete Account Confirmation Dialog -->
  <v-dialog v-model="showDeleteConfirmation" max-width="500px">
    <v-card>
      <v-card-title class="text-h6">{{ $t("user_profile.Delete_account") }}</v-card-title>
      <v-card-text>
        <p>{{ $t("user_profile.Are_you_sure_you_want_to_delete_") }}</p>
        <p>{{ $t("user_profile.This_action_cannot_be_undone_") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDeleteConfirmation = false">{{ $t("common5.Cancel") }}</v-btn>
        <v-btn color="error" @click="deleteAccount">{{ $t("user_profile.Delete") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Reset Password Dialog -->
  <v-dialog v-model="showResetPasswordDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h6">{{ $t("user_profile.Reset_password") }} </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="resetPassword">
          <reset-password-fields
            v-model:new-password="newPassword"
            v-model:confirm-password="confirmPassword"
            v-model:passwords-valid="passwordsValid"
          ></reset-password-fields>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showResetPasswordDialog = false">{{ $t("common5.Cancel") }}</v-btn>
            <v-btn type="submit" color="primary" :disabled="!passwordsValid">{{
              $t("user_profile.Reset")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <subscription-plans :active="true"></subscription-plans>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import { useAuthStore } from "../stores/auth";
import { useLangStore } from "./../stores/lang";

import ResetPasswordFields from "../components/ResetPasswordFields.vue";
import SubscriptionPlans from "./../components/SubscriptionPlans.vue";
import MainButton from "../components/general/MainButton.vue";

const authStore = useAuthStore();
const firstName = ref("");
const lastName = ref("");
const email = ref("");

const router = useRouter();

const langStore = useLangStore();
const locale = langStore.getLocale();

const showDeleteConfirmation = ref(false);

const isEditMode = ref(false);

const showResetPasswordDialog = ref(false);
const newPassword = ref("");
const confirmPassword = ref("");
const passwordsValid = ref(false);

const loading = ref(false);

onMounted(() => {
  loadUserInfo();
});

const getTextFieldClass = () => {
  return isEditMode.value ? "app-user-profile-editable-field" : "app-user-profile-readonly-field";
};

const toggleEditMode = () => {
  isEditMode.value = !isEditMode.value;
};

const cancelEdit = () => {
  loadUserInfo();
  isEditMode.value = !isEditMode.value;
};

watch(showResetPasswordDialog, (newValue, oldValue) => {
  if (newValue === false) {
    newPassword.value = "";
    confirmPassword.value = "";
  }
});

async function loadUserInfo() {
  try {
    loading.value = true;
    const user = await authStore.getUser();
    firstName.value = user.firstName;
    lastName.value = user.lastName;
    email.value = user.email;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}

async function saveProfile() {
  try {
    loading.value = true;
    await authStore.updateUser(firstName.value, lastName.value);
  } catch (error) {
    alert(error.msg);
  } finally {
    isEditMode.value = !isEditMode.value;
    loading.value = false;
  }
}

async function resetPassword() {
  if (newPassword.value !== confirmPassword.value) {
    alert("Passwords do not match!");
    return;
  }
  try {
    loading.value = true;
    await authStore.resetPassword(newPassword.value, confirmPassword.value);

    showResetPasswordDialog.value = false;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}

async function deleteAccount() {
  try {
    loading.value = true;
    await authStore.deleteAccount();

    router.push(composeRoute(locale.value, "signup"));
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.app-user-profile-readonly-field {
  background-color: #f5f5f5;
}
.app-user-profile-editable-field {
}

.user-profile-container {
  margin-top: 3rem;
  margin-left: 6rem;
  margin-right: 6rem;

  background-color: var(--knittable-isa);
}

.app-sm-and-down .user-profile-container {
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.user-profile-button-container {
  margin: 0rem 7rem 3rem;
}

.app-sm-and-down .user-profile-button-container {
  margin: 0rem 2rem;
}
</style>
