<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("forgot_password.New_password") }}</h3>
  <div class="app-central-container">
    <div class="app-central-card">
      <v-card-text v-if="!successfullyRequested">
        <v-form v-model="forgotPasswordFormValid" @submit.prevent="requestNewPassword">
          <v-container>
            <v-row>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="$t('common_user_settings.E_mail')"
                required
              ></v-text-field>
            </v-row>

            <main-button class="submit-btn" color="purple" :disabled="!forgotPasswordFormValid">
              {{ $t("forgot_password.Submit") }}
            </main-button>
          </v-container>
        </v-form>
      </v-card-text>
      <div v-else>
        {{ $t("forgot_password.A_new_password_has_successfully_been_requested") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { useAuthStore } from "../stores/auth";
import MainButton from "../components/general/MainButton.vue";
import { useLangStore } from "./../stores/lang";
import { emailRules } from "./../lib/formRules.js";

const authStore = useAuthStore();

const langStore = useLangStore();
const locale = langStore.getLocale();

const props = defineProps(["showDialog"]);

const forgotPasswordFormValid = ref(false);
const email = ref("");

const successfullyRequested = ref(false);

const loading = ref(false);

async function requestNewPassword() {
  try {
    loading.value = true;
    await authStore.forgotPassword(email.value, locale.value);

    successfullyRequested.value = true;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";
p {
  margin-bottom: 1rem;
}

.sign-in-inputs {
  padding-bottom: 1rem;
}

.submit-btn {
  margin-top: 1.5rem;
}
</style>
