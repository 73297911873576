<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("forgot_password.New_password") }}</h3>
  <div class="app-central-container">
    <div class="app-central-card">
      <div v-if="!successfullyReset">
        <v-row align="center" justify="center" no-gutters style="height: 250px">
          <reset-password-fields
            v-model:new-password="newPassword"
            v-model:confirm-password="confirmPassword"
            v-model:passwords-valid="passwordsValid"
          ></reset-password-fields>
        </v-row>
        <v-row justify="center" no-gutters>
          <main-button color="purple" :disabled="!passwordsValid" @click="resetPassword">
            {{ $t("forgot_password.Submit") }}</main-button
          >
        </v-row>
      </div>
      <div v-else>
        {{ $t("forgot_password.Password_successfully_reset") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { useAuthStore } from "../stores/auth";
import MainButton from "../components/general/MainButton.vue";
import ResetPasswordFields from "./../components/ResetPasswordFields.vue";

const authStore = useAuthStore();

const route = useRoute();
const code = route.params.code;

const newPassword = ref("");
const confirmPassword = ref("");
const passwordsValid = ref(false);

const loading = ref(false);

const successfullyReset = ref(false)

async function resetPassword() {
  if (newPassword.value !== confirmPassword.value) {
    alert("Passwords do not match!");
    return;
  }
  try {
    loading.value = true;
    await authStore.resetPassword(newPassword.value, confirmPassword.value, code);

    successfullyReset.value = true;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>
