import axios from "../axios";
import { useLangStore } from "../stores/lang";

export const addToMailingList = async (email, topic) => {
  const langStore = useLangStore();
  await axios.post("/mailing-list", {
    email: email,
    topic: topic,
    language: langStore.getLocale().value,
  });
  return;
};
